<template>
	<SingleLayout service="client" :schema="schema" @itemUpdated="init">
		<template #toolbar-left="{id,item}">
      <h1 style="margin: auto"><span>{{ $tc('enduser',1) }}<br></span>{{item.name}}</h1>
		</template>
		
		<template #default="{save,item,errors,update}">
			<FormRadium :tabs="tabs" :item="item" @save="save" @update="update(...$event)" noteClass="p-col-6">
				<template #info-header>
					<i class="pi pi-info p-pl-1"></i>
					<span>{{$t('information')}}</span>
				</template>

        <template #parent-header>
          <span>{{$t('referent')}}</span>
        </template>
				
				<template #info>
          <h3 class="p-mt-4">{{$t('origin')}}</h3>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col-12">
              <FormInput class="p-mt-3" :errors="errors.contact" v-slot="classes">
								<span class="p-float-label">
									<Dropdown v-model="item.contact"
                            @change="update('$set','contact',$event.value)"
                            :options="typesContact"
                            optionLabel="label"
                            optionValue="value"
                            :class="classes"/>
									<label>{{ $t('type_contact') }}</label>
								</span>
              </FormInput>
            </div>
          </div>

          <h3 class="p-mt-4">{{$tc('general',1)}}</h3>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col-12">
							<FormInput class="p-mt-3" :errors="errors.name" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.name" @change="update('$set','name',$event.target.value)" :class="classes"/>
									<label>{{ $t('nom') }}</label>
								</span>
							</FormInput>
						</div>
            <div class="p-field p-col-12">
              <FormInput class="p-mt-3" :errors="errors.email" v-slot="classes">
                    <span class="p-float-label">
                      <InputText v-model="item.email" @update:modelValue="update('$set','email',$event)" :class="classes"/>
                      <label>{{ $t('email') }}</label>
                    </span>
              </FormInput>
            </div>
            <div class="p-field p-col-12">
              <FormInput class="p-mt-3" :errors="errors.telephone" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.telephone" @change="update('$set','telephone',$event.target.value)" :class="classes"/>
									<label>{{ $t('telephone') }}</label>
								</span>
              </FormInput>
            </div>
            <div class="p-field p-col-12">
              <FormInput class="p-mt-3" :errors="errors.fax" v-slot="classes">
								<span class="p-float-label p-input-icon-left">
									<i class="pi pi-phone"/>
									<InputMask mask="999 999-9999? x99999" v-model="item.fax" @change="update('$set','fax',$event.target.value)" :class="classes"/>
									<label>{{ $t('fax') }}</label>
								</span>
              </FormInput>
            </div>
					</div>

					<h3 class="p-mt-4">{{$t('billing')}}</h3>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.billing_address_1" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.billing_address_1" @change="update('$set','billing_address_1',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_1') }}</label>
								</span>
							</FormInput>
						</div>
					</div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.billing_address_2" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.billing_address_2" @change="update('$set','billing_address_2',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_2') }}</label>
								</span>
							</FormInput>
						</div>
					</div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.billing_address_3" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.billing_address_3" @change="update('$set','billing_address_3',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_3') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.billing_address_4" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.billing_address_4" @change="update('$set','billing_address_4',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_4') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
					<div class="p-fluid p-formgrid p-grid">
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.billing_city" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.billing_city" @change="update('$set','billing_city',$event.target.value)" :class="classes"/>
									<label>{{ $tc('ville',1) }}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.billing_province" v-slot="classes">
								<span class="p-float-label">
									<Dropdown v-model="item.billing_province"
									          @change="update('$set','billing_province',$event.value)"
									          :options="provinces"
									          optionLabel="label"
									          optionValue="value"
									          :class="classes"/>
									<label>{{ $tc('province',1) }}</label>
								</span>
							</FormInput>
						</div>
						<div class="p-field p-col">
							<FormInput class="p-mt-3" :errors="errors.billing_postal_code" v-slot="classes">
								<span class="p-float-label">
									<InputMask mask="a9a 9a9" v-model="item.billing_postal_code" @change="update('$set','billing_postal_code',$event.target.value.toUpperCase())" :class="classes"/>
									<label>{{ $t('code_postal') }}</label>
								</span>
							</FormInput>
						</div>
					</div>

          <h3 class="p-mt-4">{{$t('shipping')}}</h3>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_address_1" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipping_address_1" @change="update('$set','shipping_address_1',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_1') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_address_2" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipping_address_2" @change="update('$set','shipping_address_2',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_2') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_address_3" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipping_address_3" @change="update('$set','shipping_address_3',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_3') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_address_4" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipping_address_4" @change="update('$set','shipping_address_4',$event.target.value)" :class="classes"/>
									<label>{{ $t('adresse_ligne_4') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
          <div class="p-fluid p-formgrid p-grid">
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_city" v-slot="classes">
								<span class="p-float-label">
									<InputText type="text" v-model="item.shipping_city" @change="update('$set','shipping_city',$event.target.value)" :class="classes"/>
									<label>{{ $tc('ville',1) }}</label>
								</span>
              </FormInput>
            </div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_province" v-slot="classes">
								<span class="p-float-label">
									<Dropdown v-model="item.shipping_province"
                            @change="update('$set','shipping_province',$event.value)"
                            :options="provinces"
                            optionLabel="label"
                            optionValue="value"
                            :class="classes"/>
									<label>{{ $tc('province',1) }}</label>
								</span>
              </FormInput>
            </div>
            <div class="p-field p-col">
              <FormInput class="p-mt-3" :errors="errors.shipping_postal_code" v-slot="classes">
								<span class="p-float-label">
									<InputMask mask="a9a 9a9" v-model="item.shipping_postal_code" @change="update('$set','shipping_postal_code',$event.target.value.toUpperCase())" :class="classes"/>
									<label>{{ $t('code_postal') }}</label>
								</span>
              </FormInput>
            </div>
          </div>
				</template>
        <template #parent>
          <Author @change="update('$set', 'author', $event);checkEvent($event)" :model-value="item.author"></Author>
        </template>
        <template #note>
          <div class="p-col-6 p-inputtext p-toolbar">
            <div>
              {{ item.name }}<br>
              {{ item.telephone }}<br>
              {{ item.email }}
            </div>
          </div>
        </template>
			</FormRadium>
		</template>
	
	</SingleLayout>
</template>

<script>

import FormDialog from "@/components/FormPrime/FormDialog";
import FormInput from "@/components/FormPrime/FormInput";
import SingleLayout from "../Layouts/SingleLayout";
import FormRadium from "@/components/FormPrime/FormRadium";
import CalendarField from "@/components/CalendarField/CalendarField";
import {computed, ref} from "vue";
import {provinces} from "@/helpers/provinces";
import {useStore} from "@/store";
import ShippingAddons from "../Settings/ShippingAddons";
import {useI18n} from "vue-i18n";
import {_GET} from "../../helpers/get";

import {FilterMatchMode,FilterOperator} from 'primevue/api';
import TableLayout from "../Layouts/TableLayout";
import {translatedFieldName, translatedString} from "../../i18n";

export default {
	name: 'EditClient',
	components: {
		SingleLayout,
		FormRadium,
		TableLayout,
		FormInput,
		FormDialog,
		CalendarField,
    ShippingAddons,
    Author: require('@/components/Author/Author').default,
    DataTable: require('primevue/datatable').default,
		Toolbar: require('primevue/toolbar').default,
		Column: require('primevue/column').default,
		Button: require('primevue/button').default,
		Dialog: require('primevue/dialog').default,
		InputNumber: require('primevue/inputnumber').default,
		RadioButton: require('primevue/radiobutton').default,
		Dropdown: require('primevue/dropdown').default,
		InputText: require('primevue/inputtext').default,
		SplitButton: require('primevue/splitbutton').default,
		Calendar: require('primevue/calendar').default,
		Editor: require('primevue/editor').default,
		InputMask: require('primevue/inputmask').default,
	},
  data(){
    return {
      users: []
    };
  },
	setup(props, {emit}) {
    const i18n = useI18n();
    const store = useStore();
		const radium = store.radium;

    const typesContact = ref([
      {
        label: i18n.t('telephone'),
        value: 'telephone'
      },
      {
        label: i18n.t('email'),
        value: 'email'
      },
      {
        label: i18n.t('website'),
        value: 'website'
      }
    ]);

    const parentOptions = ref([]);
    const selectedParent = ref('');
    const single = ref({});

		return {
			provinces,
      typesContact,
      parentOptions,
      selectedParent,
      single,
			schema: {
        name: {type: "string", min: 3, max: 255},
        telephone: {type: "string", optional: true},
        fax: {type: "string", optional: true},
        email: {type: "email", optional: true},
        billing_address_1: {type: "string", optional: true},
        billing_address_2: {type: "string", optional: true},
        billing_address_3: {type: "string", optional: true},
        billing_address_4: {type: "string", optional: true},
        billing_city: {type: "string", optional: true},
        billing_province: {type: "string", optional: true},
        billing_postal_code: {type: "string", optional: true},
        same: {type: "boolean", optional: true, default:false},
        shipping_address_1: {type: "string", optional: true},
        shipping_address_2: {type: "string", optional: true},
        shipping_address_3: {type: "string", optional: true},
        shipping_address_4: {type: "string", optional: true},
        shipping_city: {type: "string", optional: true},
        shipping_province: {type: "string", optional: true},
        shipping_postal_code: {type: "string", optional: true},
        parent_type: {type: "string", optional: true},
        parent: {type: "string", optional: true, default:''}
			},
			tabs: {
				info: true,
        parent: true,
				notable: true,
				timeData: true,
			},
			mappedProvinces: computed(() => {
				return provinces.reduce((a, v) => {
					a[v.value] = v.label;
					return a;
				}, {});
			})
		}
	},

  methods:{
    init(item){
      this.single.value = item;
      if(this.single.value.parent_type&&this.single.value.parent){
        this.getParentOptions(this.single.value.parent_type);
      }
    },
    checkEvent(event){
      console.log(event);
    },
    updateUsers(event,item,update){
      const values = event[0];
      const clicked = [event[1]];
      if(item._id){
        console.log('clicked : '+clicked);
        update('$set','users',values);
        _GET.update_merge('admin',clicked,'clients',item._id);
      }
    },
    async getParentOptions(type){
      this.parentOptions.value = [];
      await _GET.all(type).then(response => {
          response.forEach(item => {
            if(type==='admin'){
              this.parentOptions.push({name:item.username,code:item._id});
            }else{
              this.parentOptions.push({name:item.customer_name,code:item._id});
            }
          });
      });
    },
    async getUsers(){
      this.users = await _GET.all('admin');
    },

  },
  mounted() {
  }
}
</script>
